<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import PedidoService from "@/services/PedidoService";
const itemService = new PedidoService();
import HelperJS from "@/functions/general";
const helper = new HelperJS();
import moment from "moment";

import {  mapState } from "vuex";
/**
 * Shops component
 */
const nameSeccion = 'Pedido';
const namePlural = nameSeccion+'s';
const routeParentName = 'pedidos';
export default {
  components: {
    Layout,
    PageHeader,
  },
  page: {
    title: "Listado de "+ namePlural,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: namePlural,
      items: [
        {
          text: namePlural,
        },
        {
          text:  namePlural +' del dia',
          active: true,
        },
      ],
      invoicelist: [
       
      ],
      info_page:{
        routeParentName: null,
        nameSeccion: null,
      },
      formData:{
        received: null,
        shipping_status: null,
        id: null,
        user_id: null,
        shipping_date: null,

      },
      statusPayment: [
        {
          value: 0,
          text: "Pendiente",
          class: "bg-soft-warning",
        },
        {
          value: 1,
          text: "Pagado",
          class: "bg-soft-success",
        },
        {
          value: 2  ,
          text: "Cancelado",
          class: "bg-soft-danger",
        }
      ],
      statusShipping: [
        {
          value: 0,
          text: "En preparación",
          class: "bg-soft-warning",
        },
        {
          value: 1,
          text: "En ruta",
          class: "bg-soft-info",
        },
        {
          value: 2,
          text: "Entregado",
          class: "bg-soft-success",
        },
        {
          value: 3,
          text: "Cancelado",
          class: "bg-soft-danger",
        }
      ],
    };
  },
  middleware: "authentication",
  
  computed: {
    /**
     * Total no. of records
     */
  ...mapState('auth', ['currentUser']),
  },
  created(){

    this.initPage();
    this.chargeItems();
    this.dashboardRefreshInterval = setInterval(() => {
      console.log('temporizador');
        this.chargeItems();
      }, 60000); // 60000 milisegundos = 1 minuto
  },
  beforeDestroy() {
  // Limpia el temporizador cuando el componente se destruye para evitar fugas de memoria
    clearInterval(this.dashboardRefreshInterval);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    initPage(){
      this.info_page.routeParentName = routeParentName;
      this.info_page.nameSeccion = nameSeccion;
    },
    chargeItems(){
      let params = {
        shipping_from: moment().format('YYYY-MM-DD'),
        shipping_to: moment().format('YYYY-MM-DD'),
        dashboard: 'on'
      };

        itemService.getList(params).then(response=>{
          this.invoicelist = response.data.object_data;
        });

    },
    formatDate(date, format = "short"){
      return helper.formatDate(date, format);
    },
    formatMoney(money){
      return helper.formatMoney(money);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
     routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
    async shippingStatusChanged(id, event) {
      const selectedValue = event.target.value;
      
      if(selectedValue == 2){
        this.formData.id = id;
        this.$bvModal.show('modal-shipping')
      }else{
        let formData = {...this.formData};
        formData.id = id;
        formData.shipping_date = moment().format('YYYY-MM-DD hh:mm:ss');
        formData.user_id = this.currentUser.id;
        formData.shipping_status = selectedValue;
        formData.received = null;
        

      
        await itemService.updateEstado(formData).then(response=>{
          console.log('response', response);
          this.formData = {
            received: null,
            shipping_status: null,
            id: null,
            user_id: null,
            shipping_date: null,

          };
        });
      }
        //this.formData.received = moment().format('YYYY-MM-DD');
      console.log("Nuevo valor seleccionado:", selectedValue);
      // lógica para manejar el evento change del select
    },
    async formSubmit() {
      //console.log('enviado', this.$v);
      //this.submitted = true;
        console.log('validado');
      let formData = {...this.formData};
      formData.shipping_date = moment().format('YYYY-MM-DD hh:mm:ss');
      formData.user_id = this.currentUser.id;
      formData.shipping_status = 2;

      
        await itemService.updateEstado(formData).then(response=>{
          console.log('response', response);
          this.formData = {
            received: null,
            shipping_status: null,
            id: null,
            user_id: null,
            shipping_date: null,

          };
            this.closeModal('modal-shipping');
        });
        
      

       
      //}
      
      // stop here if form is invalid
      //this.$v.$touch();
    },
    closeModal(nameModal){
        this.$bvModal.hide(nameModal)
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mb-2">
      <div class="col-md-6">
        <div class="mb-3">
          <button type="button" class="btn btn-success mb-3"  @click="routeGo(info_page.routeParentName+'/agregar')">
            <i class="mdi mdi-plus me-1"></i> Agregar {{info_page.nameSeccion}}
          </button>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-inline float-md-end mb-3">
          <div class="search-box ms-2">
            <div class="position-relative">
              <input
                type="text"
                class="form-control rounded border-0"
                placeholder="Search..."
              />
              <i class="mdi mdi-magnify search-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div
        class="col-xl-4 col-sm-6"
        v-for="(item, index) in invoicelist"
        :key="index"
      >
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="avatar-sm me-4">
                <span
                  :class="`avatar-title bg-soft-${item.color} text-${item.color} font-size-16 rounded-circle`"
                >
                  {{ item.direccion.full_name.charAt(0) }}
                </span>
              </div>
              <div class="media-body align-self-center">
                <div class="border-bottom pb-1">
                  <h5 class="text-truncate font-size-16 mb-1">
                    <a href="#" class="text-dark">{{item.direccion.full_name }}</a>
                  </h5>
                  <p class="text-muted">
                    <i class="mdi mdi-account me-1"></i> {{ item.cliente.full_name }}
                  </p>
                  <a
                        @click="routeGo('/pedidos/ver/'+item.id)"
                        class="px-2 text-primary corner-right "
                        v-b-tooltip.hover
                        title="Ver detalles"
                      >
                        <i class="uil uil-eye font-size-24"></i>
                      </a>
                </div>
                <div class="border-bottom pb-2 pt-2">
                
                  <p class="text-muted text-truncate mb-1" v-for="(arreglo, idxkey) in item.arreglos"  :key="idxkey">
                    <i class="mdi mdi-flower me-1"></i> {{ arreglo.arreglo.name }}
                  </p>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="mt-3">
                      <p class="font-size-14 text-muted mb-0">{{item.zona.name}}</p>
                      <p class="font-size-14 text-muted mb-0">{{item.horario.text_short}}</p>
                      <select  v-on:change="shippingStatusChanged(item.id, $event)" class="form-control select2 select-dashboard mt-2" v-model="item.shipping_status" :class="statusShipping[item.shipping_status].class">
                        <option  :value="element.value" v-for="element in statusShipping" :key="element.value" v-text="element.text"></option>
                      </select>

                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mt-3 text-aling-right">
                      <label class="font-size-12 text-muted mb-0  d-block" >PAGO</label>
                      <div class="badge rounded-pill  font-size-12 mb-2" :class="statusPayment[item.payment_status].class">
                        {{ statusPayment[item.payment_status].text }}
                      </div>
                      
                      <h5 class="font-size-14 mb-0 d-block">{{ formatMoney(item.total) }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row 

    <div class="row">
      <div class="col-xl-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-primary"
            ><i
              class="mdi mdi-loading mdi-spin font-size-20 align-middle me-2"
            ></i>
            Load more
          </a>
        </div>
      </div>
    </div>
    !-- end row -->

    
    <b-modal
                id="modal-shipping"
                title="Entregar Arreglo"
                title-class="font-18" hide-footer
              >
          <div class="card-body">
              <div class="row">
                  <div class="col-12">
                   
                    <div class="form-group row mb-3">
                      <label class="col-form-label" for="nameAccount"
                        >Nombre de quién recibe </label
                      >
                      <div class="col-md-12">
                        <input
                          v-model="formData.received"
                          id="received"
                          type="text"
                          name="received"
                          class="form-control"
                        />
                      </div>
                    </div>
                  
        

                    <div class="box-footer">
                       <div class="col text-end ms-1">
                        <a  class="btn btn-danger" @click.prevent="closeModal('modal-shipping')">
                          <i class="uil uil-times me-1" ></i> Cancelar
                        </a>
                        <button   class="btn btn-success ms-1" @click.prevent="formSubmit">
                          <i class="uil uil-file-alt me-1"></i> Guardar
                        </button>
                      </div>
                    </div>
                    
                  </div>
                  <!-- end col -->
                </div>
          </div>       
  </b-modal>
  </Layout>
</template>
